import { IServerSideProps } from 'src/interfaces/server'
import { NextRequest, NextResponse } from 'next/server'
import decoder from 'jwt-decode'

export function serverSideRedirect(to: 'dashboard' | 'login' | string, permanent: boolean = false): IServerSideProps {
  return {
    redirect: {
      destination: `/${to}`,
      permanent,
    },
  }
}

/**
 * Checks a refresh token and returns a boolean if it is valid or not. Though it doesn't verify the token.
 * @param token a refresh token
 * @returns {boolean} boolean result
 */
export function isValidRefreshToken(token: string): boolean {
  try {
    if (!token) throw new Error('No token found')
    const decoded: any = decoder(token)
    const { token_type, iat, exp, email, id } = decoded
    const now = Math.floor(Date.now() / 1000)
    if (iat > now || now > exp || !id || !email || token_type !== 'refresh') throw new Error('Invalid token')
    return true
  } catch (error) {
    return false
  }
}

export function authMiddleware(req: NextRequest) {
  try {
    const refresh = req.cookies.get('refresh')
    if (!isValidRefreshToken(refresh as string)) throw new Error('Auth error')
  } catch (error) {
    const url = req.nextUrl.clone()
    url.pathname = '/login'
    return NextResponse.redirect(url)
  }
}

export function unauthMiddleware(req: NextRequest) {
  try {
    const refresh = req.cookies.get('refresh')
    if (isValidRefreshToken(refresh as string)) {
      const url = req.nextUrl.clone()
      url.pathname = '/dashboard'
      return NextResponse.redirect(url)
    }
  } catch (error) {
    console.log(error)
  }
}

export function doesStartWith(req: NextRequest, paths: string[]) {
  const value = paths.some((item) => req.nextUrl.pathname.startsWith(`/${item}`))
  return value
}
