import { isValidRefreshToken } from 'src/utils/server'
import { getRefreshToken } from 'src/utils/nookies'
import { AppLayout } from 'src/components/Layouts'
import { NextPageContext } from 'next'
import Head from 'next/head'

interface IProps {
  isLoggedIn: boolean
}

function Message() {
  return <h4>Page not found.</h4>
}

function Notfound({ isLoggedIn }: IProps) {
  if (!isLoggedIn) {
    return (
      <>
        <Head>
          <title>Error - 404</title>
        </Head>
        <Message />
      </>
    )
  }
  return (
    <AppLayout title='Error - 404'>
      <Message />
    </AppLayout>
  )
}

export function getInitialProps(ctx: NextPageContext): IProps {
  const token = getRefreshToken(ctx)
  const isLoggedIn = isValidRefreshToken(token)
  return { isLoggedIn }
}

export default Notfound
